import { TLoadingState } from "store/commonTypes";
import { IPresentation } from "network/rest/commonTypes";

export type TCurrentSlide =
  | { kind: "none" }
  | { kind: "some", id: number }
  | { kind: "final" }

export interface IVoteResult {
  results: {
    choiceId: number;
    score: number;
    respondents: number;
  }[];
  respondents: number;
}

export type TDemoRunning =
  | {kind: "none"}
  | {kind: 'some', value: boolean}

export interface ICreatorDemoSessionState {
  demoId?: number;
  connectionCode?: number;
  loadingState: TLoadingState;
  demoRunning: TDemoRunning;
  presentation?: {
    instance: IPresentation,
    slideOrder: number[]
  };
  currentSlide: TCurrentSlide;
  votes: { [id: number]: IVoteResult };
  votingStatus: { [id: number]: boolean }; // true - can vote; false - can't
}

export const CREATOR_DEMO_SESSION_ALIAS = 'creatorDemoSessionAlias';
