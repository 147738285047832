import makeRequest from "network/instance/makeRequest";
import { TResponse } from "network/instance/types";
import { IResponseError } from "network/rest/commonTypes";

export interface ISendUserVoteRequest {
  slideId: number;
  respondentKey: string;
  choices: {
    choiceId: number;
    value: number;
  }[];
}

export interface ISendUserVoteResponseOk {}

export type TSendUserVoteResponse =
  | ISendUserVoteResponseOk
  | IResponseError;

export const sendUserVote = (
  params: ISendUserVoteRequest
): TResponse<TSendUserVoteResponse> => {
  return makeRequest({
    url: `/slide/${params.slideId}/vote`,
    method: 'POST',
    data: {
      respondentKey: params.respondentKey,
      choices: params.choices
    },
    withCredentials: true
  });
};
