import makeRequest from "network/instance/makeRequest";
import { IResponseError } from "network/rest/commonTypes";
import { TResponse } from "network/instance/types";

export interface ISetDemoSlideRequest {
  presentationId: number,
  slideId: number,
  enableVoting?: boolean
}

export interface ISetDemoSlideResponseOk {
  demonstration: {
    id: number;
    live: boolean;
  };
  presentation: {
    id: number;
    code: number;
  };
  slide: {
    id: number;
    enableVoting: boolean;
  }
  results: {
    choices: {
      choiceId: number;
      score: number;
      respondents: number;
    }[];
    respondents: number;
  }
}

export type TSetDemoSlideResponse =
  | ISetDemoSlideResponseOk
  | IResponseError

export const setDemoSlide = (
  params: ISetDemoSlideRequest
): TResponse<TSetDemoSlideResponse> => {
  const config: any = {
    url: `/presentation/${params.presentationId}/current`,
    method: "PUT",
    data: {
      slideId: params.slideId
    }
  };

  if (typeof params.enableVoting !== "undefined") {
    config.data.enableVoting = params.enableVoting;
  }

  return makeRequest(config);
}
