import { configureStore } from '@reduxjs/toolkit';
import barChartReducer from 'store/barChart/slice';
import authReducer from 'store/auth/slice';
import presentationReducer from 'store/presentation/slice';
import editReducer from 'store/presentationEdit/slice';
import userReducer from 'store/user/slice';
import userDemoSessionReducer from 'store/userDemoSession/slice'
import creatorDemoSessionReducer from 'store/creatorDemoSession/slice'
import socketMiddleware from "./userDemoSession/websocketMiddleware";

export const store = configureStore({
  reducer: {
    barChart: barChartReducer,
    auth: authReducer,
    presentation: presentationReducer,
    presentationEdit: editReducer,
    user: userReducer,
    userDemoSession: userDemoSessionReducer,
    creatorDemoSession: creatorDemoSessionReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(socketMiddleware()),
  devTools: true, // TODO добавить проверку на режим разработки
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
