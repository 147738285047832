import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getUserState } from 'store/user/selectors';
import { logoutAction } from 'store/auth/thunk';
import { fetchUserInfoAction } from 'store/user/thunk';
import { getUserID } from 'store/auth/selectors';
import TextButton from 'components/TextButton';
import styles from './index.module.scss';

import { ReactComponent as UserPic } from './svg/userphoto.svg';

const UserProfile = ({
  flipped,
  small,
  logout = false,
}: {
  flipped?: boolean;
  small?: boolean;
  logout?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { name, email } = useAppSelector(getUserState);
  const userID = useAppSelector(getUserID);
  const navigate = useNavigate();

  useEffect(() => {
    if (userID) {
      dispatch(fetchUserInfoAction({}));
    }
  }, [userID]);

  return (
    <div
      className={classNames(styles.user_profile, {
        [styles.user_profile__small]: small,
      })}
    >
      <div
        className={classNames(styles.user_profile__info__container, {
          [styles.user_profile__flipped]: flipped,
        })}
      >
        <div className={styles.user_profile__info}>
          <p className={styles.user_profile__name}>{name}</p>
          {logout && <p className={styles.user_profile__email}>{email}</p>}
        </div>
        <div className={styles.user_profile__pic}>
          <UserPic />
        </div>
      </div>
      {logout && (
        <TextButton
          className={styles.logout_button}
          text="Выйти"
          onClick={() => {
            dispatch(logoutAction({}));
            navigate('/');
          }}
          danger
          size="small"
        />
      )}
    </div>
  );
};

export default UserProfile;
