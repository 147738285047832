/* eslint no-lonely-if: 0 */

import { Client } from '@hapi/nes/lib/client';
import { baseHostName } from 'network/instance/types';
import {
  clearPresentation,
  closeConnection,
  connectionEstablished,
  setCurrentSlideId,
  setDemoStatus,
  startConnection,
} from './slice';
import { EStatus } from './types';
import { getPresentationAction } from './thunk';

interface IMessage {
  demonstration: {
    id: number;
    live: boolean;
  };
  presentation: {
    id: number;
    code: number;
  };
  slide: {
    id: number;
    enableVoting: boolean;
  };
}

const handler = (store) => (message: IMessage | string, _) => {
  if (typeof message === 'string') {
    store.dispatch(setDemoStatus(EStatus.NoDemonstration));
  } else {
    const { demonstration, slide } = message;

    if (!demonstration.live) {
      store.dispatch(setDemoStatus(EStatus.NoDemonstration));
    } else {
      if (
        store.getState().userDemoSession.demonstrationStatus ===
        EStatus.NoDemonstration
      ) {
        // презентация началась
        store.dispatch(clearPresentation());
        store.dispatch(
          getPresentationAction({
            code: store.getState().userDemoSession.code,
          }),
        );
      }

      if (store.getState().userDemoSession.currentSlideId !== slide.id) {
        store.dispatch(setCurrentSlideId(slide.id));

        if (!slide.enableVoting) {
          store.dispatch(setDemoStatus(EStatus.VotingClosed));
        } else {
          store.dispatch(setDemoStatus(EStatus.Idle));
        }
      } else {
        if (
          store.getState().userDemoSession.demonstrationStatus !==
          EStatus.JustVoted
        ) {
          if (!slide.enableVoting) {
            store.dispatch(setDemoStatus(EStatus.VotingClosed));
          } else {
            store.dispatch(setDemoStatus(EStatus.Idle));
          }
        }
      }
    }
  }
};

const socketMiddleware = () => {
  let client: Client | null = null;

  const onConnect = (store) => () => {
    store.dispatch(connectionEstablished());
  };

  return (store) => (next) => (action) => {
    const { code } = store.getState().userDemoSession;

    if (startConnection.match(action)) {
      client = new Client(`wss://${baseHostName}/websocket/`);
      client.onConnect = onConnect(store);

      client.connect().then(() => {
        client!.subscribe(`/demo/${code}`, handler(store)).then(() => {});
      });
    } else if (closeConnection.match(action)) {
      client?.disconnect();
    }

    return next(action);
  };
};

export default socketMiddleware;
