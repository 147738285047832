import classNames from "classnames";
import React, { ReactNode, HTMLAttributes } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";

export interface INavItem extends HTMLAttributes<HTMLDivElement> {
  path: string;
  label: string;
  icon: React.ReactNode;
}

export const NavItem = (props: INavItem) => {
  const navigate = useNavigate();
  const { path, label, icon, className, onClick, ...rest } = props;

  return (
    <div
      {...rest}
      className={classNames(className, styles.nav_item)}
      onClick={(e) => {
        navigate(path);
        onClick?.(e);
      }}
    >
      <div className={styles.nav_item__icon}>{icon}</div>
      <div className={styles.nav_item__label}>{label}</div>
    </div>
  );
};

function composeItems(
  items: INavItem[],
  selectedKey?: string,
  onClick?: () => void
) {
  return items.map((item) => (
    <NavItem
      key={item.path}
      {...item}
      className={classNames({
        [styles.nav_item__selected]: item.path === selectedKey
      })}
      onClick={onClick}
    />
  ))
}

interface ISideNav {
  upperItems?: INavItem[];
  lowerItems?: INavItem[];
  arbitraryLowerComponents?: ReactNode[];
  selectedKey?: string;
  onClick?: () => void;
}

const SideNav = (props: ISideNav) => {
  const {
    selectedKey,
    upperItems,
    lowerItems,
    arbitraryLowerComponents,
    onClick
  } = props;

  return (
    <div className={styles.side_nav}>
      <div className={styles.side_nav__upper}>
        {composeItems(upperItems ?? [], selectedKey, onClick)}
      </div>
      <div className={styles.side_nav__lower}>
        {composeItems(lowerItems ?? [], selectedKey, onClick)}
        {arbitraryLowerComponents}
      </div>
    </div>
  );
};

export default SideNav;
