import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';
import { IChoice, IResponseError } from './types';

export interface IUpdateChoiceRequest {
  id: number;
  order?: number;
  label?: string;
  image?: string;
  correctAnswer?: boolean;
}

export type TAddChoiceResponse = IChoice | IResponseError;

export const updateChoiceRequest = (
  data: IUpdateChoiceRequest,
): TResponse<TAddChoiceResponse> => {
  return makeRequest({
    url: '/choice', // baseUrl подставиться сам,
    method: 'PUT',
    data,
  });
};
