import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import sortByOrder from 'helpers/sortByOrder';
import {
  getCurrentDemoSlideAction,
  getPresentationAction, getPresentationDemosAction,
  getSlideResultsAction,
  getSlideVotingStatusAction,
  setDemoSlideAction,
  setSlideVotingStatusAction,
  startDemoAction
} from './thunk';
import {
  CREATOR_DEMO_SESSION_ALIAS,
  ICreatorDemoSessionState,
  TCurrentSlide
} from "./types";

const initialState: ICreatorDemoSessionState = {
  loadingState: {kind: "done"},
  currentSlide: {kind: "none"},
  votes: {},
  votingStatus: {},
  demoRunning: {kind: 'none'}
};

export const creatorDemoSessionSlice = createSlice({
  name: CREATOR_DEMO_SESSION_ALIAS,
  initialState,
  reducers: {
    setCurrentSlide: (state, action: PayloadAction<{ slide: TCurrentSlide }>) => {
      state.currentSlide = action.payload.slide;
    },
    setDemoStatus: (state, action: PayloadAction<{ demoRunning: boolean }>) => {
      state.demoRunning = {kind: 'some', value: action.payload.demoRunning};
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    // startDemoAction
    builder.addCase(startDemoAction.pending, (state) => {
      state.loadingState = {kind: "loading"};
    });
    builder.addCase(startDemoAction.rejected, (state, {payload}) => {
      state.loadingState = {kind: "error", error: payload!};
      // if (payload?.statusCode === 409) {
      //   state.demoRunning = true;
      // }
    });
    builder.addCase(startDemoAction.fulfilled, (state, {payload}) => {
      state.loadingState = {kind: "done"};
      state.demoRunning = {kind: 'some', value: true};
      state.demoId = payload.id;
    });

    // getPresentationAction
    builder.addCase(getPresentationAction.pending, (state) => {
      state.loadingState = {kind: "loading"};
    });
    builder.addCase(getPresentationAction.rejected, (state, {payload}) => {
      state.loadingState = {kind: "error", error: payload!};
    });
    builder.addCase(getPresentationAction.fulfilled, (state, {payload}) => {
      state.loadingState = {kind: "done"};

      state.connectionCode = payload.code;

      const slideOrder = sortByOrder(payload.slides).map(slide => slide.id);
      state.presentation = {instance: payload, slideOrder};
    });

    // setDemoSlide
    builder.addCase(setDemoSlideAction.pending, (state) => {
      state.loadingState = {kind: "loading"};
    });
    builder.addCase(setDemoSlideAction.rejected, (state, {payload}) => {
      state.loadingState = {kind: "error", error: payload!};
    });
    builder.addCase(setDemoSlideAction.fulfilled, (state) => {
      state.loadingState = {kind: "done"};
    });

    // getSlideResults
    builder.addCase(getSlideResultsAction.pending, (state) => {
      state.loadingState = {kind: "loading"};
    });
    builder.addCase(getSlideResultsAction.rejected, (state, {payload}) => {
      state.loadingState = {kind: "error", error: payload!};
    });
    builder.addCase(getSlideResultsAction.fulfilled, (state, {payload}) => {
      state.loadingState = {kind: "done"};

      state.votes[payload.slide.id] = {
        respondents: payload.respondents,
        results: payload.choices
      };
    });

    // getSlideVotingStatus
    builder.addCase(getSlideVotingStatusAction.pending, (state) => {
      state.loadingState = {kind: "loading"};
    });
    builder.addCase(getSlideVotingStatusAction.rejected, (state, {payload}) => {
      state.loadingState = {kind: "error", error: payload!};
    });
    builder.addCase(getSlideVotingStatusAction.fulfilled, (state, {payload}) => {
      state.loadingState = {kind: "done"};
      state.votingStatus[payload.slideId] = payload.enableVoting;
    });

    // getCurrentDemoSlide
    builder.addCase(getCurrentDemoSlideAction.pending, (state) => {
      state.loadingState = {kind: "loading"};
    });
    builder.addCase(getCurrentDemoSlideAction.rejected, (state, {payload}) => {
      state.loadingState = {kind: "error", error: payload!};
    });
    builder.addCase(getCurrentDemoSlideAction.fulfilled, (state, {payload}) => {
      state.loadingState = {kind: "done"};
      state.currentSlide = {kind: "some", id: payload.slideId};
    });

    // setSlideVotingStatus
    builder.addCase(setSlideVotingStatusAction.pending, (state) => {
      state.loadingState = {kind: "loading"};
    });
    builder.addCase(setSlideVotingStatusAction.rejected, (state, {payload}) => {
      state.loadingState = {kind: "error", error: payload!};
    });
    builder.addCase(setSlideVotingStatusAction.fulfilled, (state, {payload}) => {
      state.loadingState = {kind: "done"};
      state.votingStatus[payload.slideId] = payload.enableVoting
    });

    // getPresentationDemos
    builder.addCase(getPresentationDemosAction.pending, (state) => {
      state.loadingState = {kind: "loading"};
    });
    builder.addCase(getPresentationDemosAction.rejected, (state, {payload}) => {
      state.loadingState = {kind: "error", error: payload!};
    });
    builder.addCase(getPresentationDemosAction.fulfilled, (state, {payload}) => {
      state.loadingState = {kind: "done"};

      const liveDemo = payload.demos.find(demo => demo.live);
      if (liveDemo) {
        state.demoId = liveDemo.id;
        state.demoRunning = {kind: 'some', value: true};
      } else {
        state.demoRunning = {kind: 'some', value: false};
      }
    });
  }
});

export const {
  setCurrentSlide,
  clearState,
  // toggleVoting
} = creatorDemoSessionSlice.actions;

export default creatorDemoSessionSlice.reducer;
