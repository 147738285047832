import { TLoadingState } from 'store/commonTypes';

export interface IChoice {
  id: number;
  label: string;
}

export interface IMultipleChoiceSlide {
  kind: 'multipleChoice';
  title: string;
  choices: IChoice[];
}

export interface IBound {
  value: number;
  label: string;
}

export interface IScale {
  id: number;
  label: string;
}

export interface IScaleSlide {
  kind: 'scale';
  title: string;
  scales: IScale[];
  min: IBound;
  max: IBound;
}

export interface IParagraphSlide {
  kind: 'paragraph';
  title: string;
  text: string;
  layout: 'left' | 'center';
}

export type TSlide =
  | IParagraphSlide
  | IMultipleChoiceSlide
  | IScaleSlide
  | { kind: "empty" };

export enum EStatus {
  Idle = 'idle', // также для статических слайдов
  JustVoted = 'just_voted',
  AlreadyVoted = 'already_voted',
  VotingClosed = 'voting_closed',
  NoDemonstration = 'no_demonstration',
}

export type TPresentation = { [id: number]: TSlide };

export type TWebsocketStatus =
  | { kind: 'noConnection' }
  | { kind: 'connecting' }
  | { kind: 'connected' };

export type TVoteValues = { [choiceId: number]: number };

export interface IFlatVote {
  choiceId: number;
  value: number;
}

export interface IUserDemoSessionState {
  respondentKey: string | null;
  code?: number;
  websocketStatus: TWebsocketStatus;
  loadingState: TLoadingState;
  demonstrationStatus: EStatus;
  presentation?: TPresentation;
  currentSlideId?: number;
  answeredSlideIds: number[];
  currentVote: TVoteValues
}

export const USER_DEMO_SESSION_ALIAS = 'userDemoSessionAlias';
