import makeRequest from "network/instance/makeRequest";
import { IResponseError } from "network/rest/commonTypes";
import { TResponse } from "network/instance/types";

export interface IStopDemoRequest {
  presentationId: number,
}

export interface IStopDemoResponseOk {
}

export type TStopDemoResponse =
  | IStopDemoResponseOk
  | IResponseError

export const stopDemo = (
  params: IStopDemoRequest
): TResponse<TStopDemoResponse> => {
  return makeRequest({
    url: `/presentation/${params.presentationId}/stop`,
    method: "PUT"
  })
}
