import { EBackendRoutes, EEnvironment } from './types';

export const getBaseUrl = () => {
  if (process.env.NODE_ENV === EEnvironment.Development) {
    return EBackendRoutes.Development;
  }
  if (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === EEnvironment.Stage) {
    return EBackendRoutes.Stage;
  }
  if (process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === EEnvironment.Production) {
    return EBackendRoutes.Production;
  }
  return EBackendRoutes.Production;
  // return window.location.hostname;
};

interface IConfig {
  baseUrl: string;
  token: string | null;
}

export const config: IConfig = {
  baseUrl: getBaseUrl(),
  token: null,
};

export const setConfigToken = (token: string | null) => {
  config.token = token;
};

export const getToken = (): string | null => config.token;
