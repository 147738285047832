import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';

export interface IGetUserInfoRequest {
  id: number | null;
}

export interface IGetUserInfoOK {
  user: {
    name: string;
    email: string;
  };
}

export interface IResponseError {
  statusCode: number;
  error: string;
  message: string;
}

export type TUserInfoResponse = IGetUserInfoOK | IResponseError;

export const getUserInfoRequest = (
  data: IGetUserInfoRequest,
): TResponse<TUserInfoResponse> => {
  return makeRequest({
    method: 'GET',
    url: `/user/${data.id}`,
  });
};
