import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Skeleton } from 'antd';

import {
  ForgetPasswordPage,
  LogInPage,
  MainPage,
  Page404,
  PolicyPage,
  PresentationEditPage,
  SignUpPage,
  StartPage,
  UserSessionViewer,
  CreatorSessionViewer,
} from 'containers/Router';

import MainPageLayout from 'containers/layouts/MainPageLayout';
import AuthRequired from 'entities/AuthRequired';

import './App.css';
import './styles/global.scss';

const App = () => {
  return (
    <Suspense fallback={<Skeleton active />}>
      <Routes>
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/login" element={<LogInPage />} />
        <Route path="/" element={<StartPage />} />
        <Route element={<MainPageLayout />}>
          <Route
            path="/presentations"
            element={
              <AuthRequired>
                <MainPage />
              </AuthRequired>
            }
          />
          <Route
            path="/templates"
            element={
              <AuthRequired>
                <div>В разработке</div>
              </AuthRequired>
            }
          />
          <Route
            path="/helps"
            element={
              <AuthRequired>
                <div>В разработке</div>
              </AuthRequired>
            }
          />
          <Route
            path="/settings"
            element={
              <AuthRequired>
                <div>В разработке</div>
              </AuthRequired>
            }
          />
        </Route>
        <Route path="/policy" element={<PolicyPage />} />
        <Route path="/restore_password" element={<ForgetPasswordPage />} />
        <Route
          path="/presentations/:presentationId"
          element={
            <AuthRequired>
              <PresentationEditPage />
            </AuthRequired>
          }
        />
        <Route
          path="/participate"
          element={<UserSessionViewer />}
        />
        <Route
          path="/presentations/:presentationId/demo"
          element={
            <AuthRequired>
              <CreatorSessionViewer />
            </AuthRequired>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  );
};

export default App;
