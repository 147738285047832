import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';

export interface ISignupRequest {
  name: string;
  email: string;
  password: string;
}

export interface ILoginRequest {
  email: string;
  password: string;
}

interface IUser {
  id: number;
  email?: string;
  password?: string;
  name?: string;
  role?: {
    id?: number;
    name?: string;
  };
  created_at?: string;
  updated_at?: string;
}

export interface ILoginSignupResponseOK {
  statusCode: number;
  userId: number;
  access_token: string;
  refresh_token: string;
  user: IUser;
}

export interface ILoginSignupResponseError {
  statusCode: number;
  error: string;
  message: string;
}

export type TLoginSignupResponse =
  | ILoginSignupResponseOK
  | ILoginSignupResponseError;

export const signUpRequest = (
  data: ISignupRequest,
): TResponse<TLoginSignupResponse> => {
  return makeRequest({
    method: 'POST',
    url: '/user',
    data,
  });
};

export const logInRequest = (
  data: ILoginRequest,
): TResponse<TLoginSignupResponse> => {
  return makeRequest({
    url: '/user/login', // baseUrl подставиться сам,
    method: 'POST',
    data,
  });
};
