import _axios from 'axios';
import {
  IRefreshTokenResponseOK,
  refreshTokensRequest,
} from 'network/rest/auth/refreshTokens';
import { REFRESH_TOKEN } from 'store/auth/types';
import { config, setConfigToken } from './config';

const axios = _axios.create({
  withCredentials: false,
  baseURL: config.baseUrl,
});

// for tokens
const exceptionRoutes: string[] = ['/user/login'];

// auto update of access token
axios.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (
      refreshToken && // there is refresh_token
      error.response &&
      error.response.status === 401 && // status code 401
      !exceptionRoutes.includes(error.config.url) && // exclude some route where no need to check/update
      error.config &&
      !error.config._isRetry // not repeated
    ) {
      originalRequest._isRetry = true;

      const response = await refreshTokensRequest({
        refresh_token: refreshToken,
      });

      const data = response.data as IRefreshTokenResponseOK;
      setConfigToken(data.access_token);
      localStorage.setItem(REFRESH_TOKEN, data.refresh_token);

      return axios.request({
        ...originalRequest,
        headers: {
          ...originalRequest.headers,
          Authorization: `Bearer ${data.access_token}`,
        },
      });
    }

    return Promise.reject(error);
  },
);

export default axios;
