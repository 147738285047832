import makeRequest from "network/instance/makeRequest";
import { IResponseError } from "network/rest/commonTypes";
import { TResponse } from "network/instance/types";

export interface IGetDemoInfoRequest {
  demoId: number;
}

export interface IGetDemoInfoResponseOk {
  id: number; // demoId
  created_at: string;
  updated_at: string;
  live: boolean;
  stopped_at: null;
  presentation: {
    id: number;
    code: number;
    author: {
      id: number
    }
  };
  slide: {
    id: number;
    enableVoting: boolean;
  }
}

export type TGetDemoInfoResponse =
  | IGetDemoInfoResponseOk
  | IResponseError;

export const getDemoInfo = (
  params: IGetDemoInfoRequest
): TResponse<TGetDemoInfoResponse> => {
  return makeRequest({
    url: `/demo/${params.demoId}`
  })
};
