import { createAsyncThunk } from "@reduxjs/toolkit";
import { IResponseError } from "network/rest/presentationEdit/types";
import {
  getPresentation,
  IGetPresentationRequest,
  IGetPresentationResponseOk
} from "network/rest/userDemoSession/getPresentation";
import {
  getUserVote,
  IGetUserVoteRequest,
  IGetUserVoteResponseOk
} from "network/rest/userDemoSession/getUserVote";
import {
  ISendUserVoteRequest,
  ISendUserVoteResponseOk, sendUserVote
} from "../../network/rest/userDemoSession/sendUserVote";
import { USER_DEMO_SESSION_ALIAS } from "./types";

export const getPresentationAction = createAsyncThunk<
  IGetPresentationResponseOk,
  IGetPresentationRequest,
  {
    rejectValue: IResponseError
  }
>(
  `${USER_DEMO_SESSION_ALIAS}/getPresentation`,
  async (payload, {rejectWithValue}) => {
    try {
      const response = await getPresentation(payload);

      if (response.status === 200) {
        const data = response.data as IGetPresentationResponseOk;
        return {...data, statusCode: response.status};
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status
      });
    }
  }
);

export const getUserVoteAction = createAsyncThunk<
  IGetUserVoteResponseOk,
  IGetUserVoteRequest,
  {
    rejectValue: IResponseError
  }
>(
  `${USER_DEMO_SESSION_ALIAS}/getUserVote`,
  async (payload, {rejectWithValue}) => {
    try {
      const response = await getUserVote(payload);

      if (response.status === 200 || response.status === 204) {
        const data = response.data as IGetUserVoteResponseOk;
        return {...data, statusCode: response.status};
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status
      });
    }
  }
);

export const sendUserVoteAction = createAsyncThunk<
  ISendUserVoteResponseOk,
  ISendUserVoteRequest,
  {
    rejectValue: IResponseError
  }
>(
  `${USER_DEMO_SESSION_ALIAS}/sendUserVote`,
  async (payload, {rejectWithValue}) => {
    try {
      const response = await sendUserVote(payload);

      if (response.status === 200 || response.status === 204) {
        const data = response.data as ISendUserVoteResponseOk;
        return {...data, statusCode: response.status};
      }

      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status
      });
    }
  }
);
