import makeRequest from "network/instance/makeRequest";
import { IResponseError } from "network/rest/commonTypes";
import { TResponse } from "network/instance/types";

export interface IGetSlideResultsRequest {
  slideId: number;
}

export interface IVotingResult {
  choiceId: number;
  score: number;
  respondents: number;
}

export interface IGetSlideResultsResponseOk {
  slide: {
    id: number;
  };
  choices: IVotingResult[];
  respondents: number;
}

export type TGetSlideResultsResponse =
  | IGetSlideResultsResponseOk
  | IResponseError;

export const getSlideResults = (
  params: IGetSlideResultsRequest
): TResponse<TGetSlideResultsResponse> => {
  return makeRequest({
    url: `/slide/${params.slideId}/results`
  })
};
