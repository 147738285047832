import React from 'react';

export const SignUpPage = React.lazy(() => import('page/SignUp'));
export const LogInPage = React.lazy(() => import('page/LogIn'));

export const PresentationEditPage = React.lazy(
  () => import('page/PresentationEdit'),
);
export const StartPage = React.lazy(() => import('page/Start'));
export const MainPage = React.lazy(() => import('page/Main'));
export const Page404 = React.lazy(() => import('page/Page404'));

export const PolicyPage = React.lazy(
  () => import('page/plugs_for_testing/Policy'),
);
export const ForgetPasswordPage = React.lazy(
  () => import('page/plugs_for_testing/ForgetPassword'),
);

export const CreatorSessionViewer = React.lazy(
  () => import("page/CreatorSessionViewer")
);
export const UserSessionViewer = React.lazy(
  () => import("page/UserSessionViewer")
);
