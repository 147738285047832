import makeRequest from 'network/instance/makeRequest';
import { TResponse } from 'network/instance/types';

export interface IRefreshTokenRequest {
  refresh_token: string;
}

export interface IRefreshTokenResponseOK {
  access_token: string;
  refresh_token: string;
  user: {
    id: number;
  };
}

export interface IRefreshTokenResponseError {
  statusCode: number;
  error: string;
  message: string;
}

export type TRefreshTokenResponse =
  | IRefreshTokenResponseOK
  | IRefreshTokenResponseError;

export const refreshTokensRequest = (
  data: IRefreshTokenRequest,
): TResponse<TRefreshTokenResponse> => {
  return makeRequest({
    url: '/auth/token', // baseUrl подставиться сам,
    method: 'POST',
    data,
  });
};
