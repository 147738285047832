/* eslint-disable no-param-reassign */
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import axios from './index';
import { getBaseUrl, getToken } from './config';

const makeRequest = ({
  url,
  method = 'GET',
  params = {},
  responseType = 'json',
  data = {},
  headers = {},
  withCredentials = false,
}: AxiosRequestConfig): AxiosPromise<any> => {
  const token = getToken();

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return axios.request({
    baseURL: getBaseUrl(),
    url,
    method,
    params,
    responseType,
    data,
    headers,
    withCredentials
  });
};

export default makeRequest;
