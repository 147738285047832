import makeRequest from "network/instance/makeRequest";
import { TResponse } from "network/instance/types";
import { IPresentation, IResponseError } from "network/rest/commonTypes";

export interface IGetPresentationRequest {
  code: number;
}

export interface IGetPresentationResponseOk {
  respondentKey: string;
  presentation: IPresentation;
}

export type TGetPresentationResponse =
  | IGetPresentationResponseOk
  | IResponseError;

export const getPresentation = (
  params: IGetPresentationRequest
): TResponse<TGetPresentationResponse> => {
  return makeRequest({
    url: `/demo/view/${params.code}`,
    withCredentials: true
  });
};
