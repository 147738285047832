import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createPresentationRequest,
  ICreatePresentationRequest,
  TCreatePresentationResponse,
} from 'network/rest/presentations/createPresentation';
import {
  deletePresentationRequest,
  TDeletePresentationResponse,
} from 'network/rest/presentations/deletePresentation';
import {
  getPresentationsRequest,
  IGetPresentationsResponseOK,
  TGetPresentationsResponse,
} from 'network/rest/presentations/getPresentationsList';
import { IPresentation } from 'network/rest/presentations/reusableTypes';
import {
  IUpdatePresentationRequest,
  TUpdatePresentationResponse,
  updatePresentationRequest,
} from 'network/rest/presentations/updatePresentation';
import { IRequestError, PRESENTATIONS_ALIAS } from './types';

export const fetchPresentationsAction = createAsyncThunk<
  TGetPresentationsResponse,
  {},
  {
    rejectValue: IRequestError;
  }
>(
  `${PRESENTATIONS_ALIAS}/fetchPresentationsAction`,
  async (_payload, { rejectWithValue }) => {
    try {
      const response = await getPresentationsRequest();
      const data = response.data as IGetPresentationsResponseOK[];
      if (response.status === 200) {
        return data;
      }
      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  },
);

export const createPresentationAction = createAsyncThunk<
  TCreatePresentationResponse | null,
  ICreatePresentationRequest,
  {
    rejectValue: IRequestError;
  }
>(
  `${PRESENTATIONS_ALIAS}/createPresentationAction`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await createPresentationRequest(payload);
      const data = response.data as IPresentation;
      if (response.status === 200 && !payload.id) {
        return data;
      }
      if (response.status === 200 && payload.id) {
        return null;
      }
      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  },
);

export const updatePresentationAction = createAsyncThunk<
  TUpdatePresentationResponse,
  IUpdatePresentationRequest,
  {
    rejectValue: IRequestError;
  }
>(
  `${PRESENTATIONS_ALIAS}/updatePresentationAction`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updatePresentationRequest(payload);
      const data = response.data as IPresentation;
      if (response.status === 200) {
        return data;
      }
      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  },
);

export const deletePresentationAction = createAsyncThunk<
  TDeletePresentationResponse,
  number,
  {
    rejectValue: IRequestError;
  }
>(
  `${PRESENTATIONS_ALIAS}/deletePresentationAction`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await deletePresentationRequest(payload);
      if (response.status === 204) {
        return response.status;
      }
      return rejectWithValue({
        statusCode: response.status,
        error: `Что-то пошло не так. Ошибка ${response.status}`,
        message: `Что-то пошло не так. Ошибка ${response.status}`,
      });
    } catch (error) {
      return rejectWithValue({
        ...error.response.data,
        statusCode: error.response.status,
      });
    }
  },
);
