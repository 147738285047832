import { AxiosResponse } from 'axios';

export type TResponse<T> = Promise<AxiosResponse<T>>;

export enum EEnvironment {
  Stage = 'stage',
  Production = 'main',
  Development = 'development',
}

export const baseHostName = 'yoreactions-back.lad-academy.ru'; // для вебсокета

export enum EBackendRoutes {
  Stage = `https://yoreactions-back.lad-academy.ru`,
  Production = `https://yoreactions-back.lad-academy.ru`,
  // Development = 'https://localhost:8000',
  Development = `https://yoreactions-back.lad-academy.ru`,
}
